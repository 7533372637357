import React from 'react'

const Social = ()=> {
    return(
        <div className="home_social">
            <a href="https://www.instagram.com/shiv_like_the_knife/" className="home_social_icon" target="_blank">
                <i className='uil uil-instagram'></i>
                <span>Instagram</span>
            </a>
            <a href="https://www.linkedin.com/in/saravana-mauree/" className="home_social_icon" target="_blank">
                <i className="uil uil-linkedin"></i>
                <span>LinkedIn</span>
            </a>
            <a href="https://github.com/ShivamMauree" className="home_social_icon" target="_blank">
                <i className="uil uil-github"></i>
                <span>GitHub</span>
            </a>
        </div>
    )
}
export default Social